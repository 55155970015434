<template>
  <div v-show="mfcSelected !== 0" >
    <nut-cell :title="'面粉厂名字:' + mfcSelectName" @click="show = true" is-link/>
    <nut-picker v-model:visible="show" :columns="mfcList" title="面粉厂选择" @change="changeMfc" />
  </div>
  <div class="goodsList" v-if="goodsList.length > 0">
    <div class="item" v-for="item in goodsList" :key="item.id">
      <div class="img">
        <img :src="item.goods_pic" />
      </div>
      <div class="info">
        <div class="title">{{ item.goods_name }}</div>
        <div class="price">￥{{ item.goods_price/100}}</div>
        <div class="num">
            <nut-inputnumber v-model="buyGoodsList[item.goods_id]" button-size="30" input-width="50" />
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <nut-empty description="无数据"></nut-empty>
  </div>
  <div class="button"  v-if="goodsList.length > 0" >
    <nut-button type="primary" @click="goBuy()">订购</nut-button>
  </div>

</template>

<script>
import { ref, watch } from "vue";
import { useOptionsConfig } from "../../hooks/optionsConfig";
import * as goodsApi from "../../api/goods";
import { Toast } from "@nutui/nutui";
import { useRouter } from "vue-router";

export default {
  setup() {
    const router = useRouter();
    const show = ref(false);
    const mfcSelectName = ref("");
    const tabValue = ref(0);
    const { mfcList, mfcSelected } = useOptionsConfig();
    const changeMfc = ({ selectedValue }) => {
      mfcSelected.value = selectedValue[0];
    };

    const getMfcNameByMfcId = (mfcId) => {
      var mfcName = "";
      var mfcListLen = mfcList.value.length;
      for( var i=0;i<mfcListLen;i++ ){
        if( mfcList.value[i].value == mfcId ){
          mfcName = mfcList.value[i].text;
        }
      }
      return mfcName;
    };
    watch(mfcSelected, (mfcId) => {
      mfcSelectName.value = getMfcNameByMfcId(mfcId);
      getGoodsList(mfcId);
    });
    const goodsList = ref([]);
    const buyGoodsList = ref({});
    const getGoodsList = async (mfcId) => {
      const res = await goodsApi.getGoodsList({ mfc_id: mfcId });
      if(res.code == 0){
        let data = res.data.list;
        let dataLength = data.length;
        let buyListObj = {};
        for( let i=0;i < dataLength;i++){
          buyListObj[data[i].goods_id] = 0;
        }
        buyGoodsList.value = buyListObj;
        goodsList.value = data;
      } else {
        Toast.text(res.message);
      }
    };

    // const changeNum = (goodsId) => {
    //   console.log(goodsId);
    //   console.log(buyGoodsList.value);
    // };
    const goBuy = () =>{
      let param = [];
      for(var key in buyGoodsList.value){
        if(buyGoodsList.value[key] >0){
          param.push({
            goods_id: key,
            num: buyGoodsList.value[key],
          });
        }
      }
      if(param.length ==0){
        Toast.text("请选择购买商品的数量");
      }else{
        router.push("/order/create?param=" + JSON.stringify(param) + "&mfc_id=" +mfcSelected.value);
      }
    };
    return {
      changeMfc,
      goBuy,
      show,
      mfcList,
      mfcSelected,
      mfcSelectName,
      tabValue,
      goodsList,
      buyGoodsList,
    };
  },
};
</script>

<style scoped lang="scss">
.mfcList {
  width: 60%;
  height: 2rem;
  border: 1px solid #a9a9a9;
}
:deep(.nut-cell__title) {
  align-items: flex-start;
  font-size: 15px;
  font-weight: 500;
}
:deep(.nut-cell__value) {
  font-size: 15px;
  font-weight: 500;
  color: #666 !important;
}
.nut-pagination {
  margin-left:20px;
}

.goodsList .item {
  margin-top: .5rem;
  background-color: #ffffff;
  height: 8rem;
}
.goodsList .item .img {
  float: left;
  margin-left: 0.3rem;
  widht:30%;
}
.goodsList .item .info {
  float: left;
  margin-left: 0.3rem;
  widht:60%;
}
.goodsList .item .info .title{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-all;
  line-height: 1rem;
  font-size: 1rem;
  width: 15rem;
  padding: .5rem;
  text-align: left;
}

.goodsList .item .info .price {
  font-size: 1rem;
  color: #fa2c19;
  line-height: 1rem;
  width: 15rem;
  text-align: left;
  margin-top: 1rem;
}

.goodsList .item .info .num {
  float: right;
  margin-top: 2rem;
}

.goodsList .item .img img {
  width:7rem;
  height:7rem;
}

.button{
  margin: 1rem 1.5rem;
  text-align: right;
}

</style>
