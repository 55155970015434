import { ref } from "vue";
import * as mfcApi from "../api/mfc";

export const useOptionsConfig = () => {
  const mfcList = ref([]);
  const mfcSelected = ref("");
  const getMfcList = async () => {
    try {
      const res = await mfcApi.mfcInfo();
      // 模拟请求返回的结果
      if (res?.data?.list?.length > 0) {
        mfcSelected.value = res.data?.list?.[0]?.mfc_id || "";
      }
      mfcList.value = (res?.data?.list || []).map((item) => ({
        text: item.mfc_name,
        value: item.mfc_id,
      }));
    } catch (error) {
      console.log(error);
    }
  };
  getMfcList();

  return {
    mfcList,
    mfcSelected,
  };
};
